
import { defineComponent, onMounted, ref } from 'vue';
import axios, { AxiosError } from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import JwtService from '@/core/services/JwtService';

export default defineComponent({
	name: 'Import-New-User',
	components: {},
	setup() {
		const fileArray = ref();
		const formRef = ref<null | HTMLFormElement>(null);
		const loading = ref<boolean>(false);

		const formData = ref({
			file: ''
		});

		const rules = ref({
			file: [
				{
					required: true,
					message: 'File is required',
					trigger: 'change'
				}
			]
		});

		const submit = () => {
			if (!formRef.value) {
				return;
			}

			formRef.value.validate(valid => {
				if (valid && fileArray.value?.name) {
					loading.value = true;

					const userData = new FormData();
					userData.append('file', fileArray.value, fileArray.value.name);
					axios
						.post(`User/registerbatch`, userData, {
							headers: {
								Authorization: `Bearer ${JwtService.getToken()}`,
								'Content-Type': `multipart/form-data;`
							}
						})
						.then(({ data }) => {
							console.log('import user response => ', data);
							loading.value = false;
							Swal.fire({
								timer: 1500,
								text: 'Form has been successfully submitted!',
								icon: 'success',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							}).then(() => {
								(document.getElementById('formRef') as HTMLFormElement).reset();
							});
						})
						.catch((error: AxiosError) => {
							console.log(
								'Import user error => ',
								error.message,
								JSON.stringify(error)
							);
							loading.value = false;
							Swal.fire({
								title: error?.message || '',
								text: 'Sorry, looks like there are some errors detected, please try again.',
								icon: 'error',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							}).then(() => {
								fileArray.value = null;
								(document.getElementById('formRef') as HTMLFormElement).reset();
							});
						});
				} else {
					Swal.fire({
						title: 'Validation error',
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
					return false;
				}
			});
		};

		const onChangeInput = event => {
			fileArray.value = event.target.files[0];
		};

		onMounted(() => {
			setCurrentPageBreadcrumbs(`Import User`, ['Users']);
		});

		return {
			rules,
			submit,
			formRef,
			loading,
			formData,
			onChangeInput
		};
	}
});
